<template>
  <div>
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="content-box" style="padding-top: 24px">
      <div class="content-main">
        <ayl-table :table="table" @selection-change="handleSelectionChange">
          <div slot="ctrl-button">
            <el-button class="btn" style="margin-left: 20px" @click="onAdd">+ 新增</el-button>
            <!-- <el-button plain @click="onExport" style="float:right">导出</el-button> -->
            <el-button plain @click="onDelete" style="float:right">车辆作废</el-button>
          </div>
        </ayl-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const vm = this;

    function _render(h, ctx, param, islink) {
      return h(
        "span",
        islink
          ? [
              h(
                "span",
                {
                  class: {
                    "table-view-hw": true
                  },
                  on: {
                    click: vm.carDetails.bind(this, ctx.row)
                  }
                },
                param || "- -"
              )
            ]
          : param || "- -"
      );
    }

    return {
      nav: [{ name: "车辆管理" }],
      plateNumbers: [],
      table: {
        api: vm.$api_hw.vehicleManagement_listSanitationCarInfoByCondition,
        query: {
          carTypes: null,
          powerType: null,
          queryContent: null
        },
        // 是否显示分页
        // hidePagination: true,
        // 表格查询的条件组件
        searchData: [
          {
            type: "cascader_two",
            title: "车辆类型",
            model: "carTypes",
            placeholder: "请选择",
            option: vm.$enums_hw.carType.list
          },
          {
            type: "select",
            title: "动力类型",
            model: "powerType",
            placeholder: "请选择",
            option: vm.$enums_hw.powerType.list
          },
          {
            type: "input",
            model: "queryContent",
            placeholder: "车牌号/设备号"
          }
        ],
        columns: [
          {
            type: "selection",
            width: "50px"
          },
          {
            title: "序号",
            width: "50px",
            $index: "index"
          },
          {
            title: "车牌号",
            // key: "plateNumber",
            render(h, ctx) {
              return _render(h, ctx, ctx.row.plateNumber, true);
            }
          },
          {
            title: "所属企业",
            key: "companyName",
            showTooltip: true,
            // width: "300px",
          },
          {
            title: "设备号",
            key: "deviceName"
          },
          {
            title: "SIM卡号",
            // width: "180px",
            key: "simCardNum"
          },
          {
            title: "车辆类型",
            key: "carTypeDesc",
            // width: "160px"
            align: "center"
          },
          {
            title: "动力类型",
            // width: "120px",
            key: "powerTypeDesc"
          },
          {
            title: "权属类型",
            // width: "180px",
            key: "belongTypeDesc"
          },
          {
            title: "所属标段",
            key: "bidName",
            showTooltip: true,
          },
          {
            title: "使用状态",
            key: "carStatusDesc"
          },
          {
            title: "操作",
            width: "230px",
            render(h, ctx) {
              return h("span", [
                h(
                  "span",
                  {
                    class: {
                      "table-view-hw": true
                    },
                    on: {
                      click: vm.editCard.bind(this, ctx.row)
                    }
                  },
                  "编辑"
                ),
                h(
                  "span",
                  {
                    class: {
                      "table-view-line-hw": true
                    },
                    on: {
                      click: ctx.row.deviceId === null ? vm.matchingCard.bind(this, ctx.row, 0) : vm.matchingCard.bind(this, ctx.row, 1)
                    }
                  },
                  ctx.row.deviceId === null ? "匹配" : "重新匹配"
                ),
                h(
                  "span",
                  {
                    class: {
                      "table-view-line-hw-red": ctx.row.deviceId === null ? false : true
                    },
                    on: {
                      click: vm.onRemove.bind(this, ctx.row)
                    }
                  },
                  ctx.row.deviceId === null ? "" : "解绑设备"
                )
              ]);
            }
          }
        ]
      }
    };
  },
  methods: {
    async onRefresh() {
      await this.$search(this.table);
    },
    /**
     * 编辑车辆
     */
    editCard(v) {
      let carNub = encodeURIComponent(v.plateNumber);
      this.$router.push({
        path: "/district-management/vehicle-management/edit-car",
        query: { id: carNub }
      });
    },
    /**
     * 匹配设备
     */
    matchingCard(val, index) {
      this.$router.push({
        path: "/district-management/vehicle-management/matching-car",
        query: {
          plateNumber: val.plateNumber,
          index: index,
        }
      });
    },
    /**
     * 车辆详情
     */
    carDetails(v) {
      let carNub = encodeURIComponent(v.plateNumber);
      this.$router.push({
        path: "/district-management/vehicle-management/car-details",
        query: { id: carNub }
      });
    },
    //导出
    onExport() {},

    handleSelectionChange(v) {
      this.plateNumbers = v.map(item => {
        return item.plateNumber;
      });
    },
    //作废车辆
    onDelete() {
      if (this.plateNumbers.length < 1) {
        this.$message({
          showClose: true,
          message: "请选择需要作废的数据！",
          type: "warning"
        });
        return;
      }
      this.$confirm("此操作将永久作废该车辆, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let param = {
            plateNumbers: [...this.plateNumbers]
          };
          this.$api_hw.vehicleManagement_obsoleteCar(param).then(res => {
            this.onRefresh();
            this.$message({
              type: "success",
              message: "作废成功!"
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消作废"
          });
        });
    },
    //解绑设备
    onRemove(val) {
      this.$confirm("此操作将解除车辆和设备的绑定关系，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$api_hw.car_unBindDevice({
            plateNumber: val.plateNumber
          }).then(res => {
            this.onRefresh();
            this.$message({
              type: "success",
              message: "解除绑定成功!"
            });
          });
        })
        .catch(() => {});
    },
    //新增车辆
    onAdd() {
      this.$router.push({
        path: "/district-management/vehicle-management/add-car"
      });
    }
  },
  async activated() {  
    let self = this;
    BUS.$on(BUSEVENT.REFRESH_VEHICLE_MANAGEMENT, () => {
     self.onRefresh();
      
    });
  },
  computed: {
    getName() {
        let hash = document.location.hash;
        let i = hash.lastIndexOf("/");
        if (i != -1) {
            hash = hash.substring(i + 1);
        }
        return /*this.$options.name ||*/ hash
    },
    /**
     * 根据URL获取当前组件的名字
     */
    getParentName() {
        let hash = document.location.hash;
        let i = hash.lastIndexOf("/");
        if (i != -1) {
            let arr = hash.split('/');
            hash = arr[arr.length - 2] || hash;
        }
        return /*this.$options.name ||*/ hash
    },
  }
};
</script>

<style lang='sass' scoped>
.btn
  width: 64px
  height: 28px
  border: 1px solid rgba(26,188,156,1)
  border-radius: 4px
  color: #1ABC9C
  
.drawer-main
  margin: 5px 15px
  .drawer-btn
    width: 92px
    height: 36px
    margin-bottom: 10px
    background: rgba(237,237,237,1)
    border-radius: 2px
    outline: none
    border: 0
    &:hover
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      border: 0
      color: #fff
    &:before
      margin-bottom: 10px
      background: rgba(26,188,156,1)
      border-radius: 2px
      border: 0
      color: #fff
  .drawer-btn1
    width: 92px
    height: 36px
    margin-bottom: 10px
    background: rgba(26,188,156,1)
    border-radius: 2px
    outline: none
    border: 1px solid transparent
    color: #fff

.drawer-contract
  margin: 30px 15px 10px
  .drawer-contract-item
    width: 100%
    .drawer-contract-span
      color: #2E3033
  .drawer-contract-item1
    width: 48%
    .drawer-contract-span
      color: #2E3033
.drawer-hr
  width: 100%
  height: 1px
  background: rgba(232,232,232,1)
.drawer-map
  margin: 20px 15px 10px
  .drawer-map-title
    color: rgba(46,48,51,1)
/deep/.el-drawer__header 
    margin-bottom: 15px
    padding-left: 15px
    color: #222
    font-size: 16px
/deep/.el-button--success 
    color: #FFFFFF
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)


/deep/.search-box .el-cascader
    width: 230px
/deep/ .search-box .el-select
    width: 180px
</style>